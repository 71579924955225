import Head from 'next/head';
import baseSiteFAQs from '~/components/Layout/FAQ/baseSiteFAQs';
import baseSiteFeatures from '~/components/Layout/Features/baseSiteFeatures';
import {
  BannerCTASkeleton,
  CompareCardsSkeleton,
  FAQSSkeleton,
  FeaturesSkeleton,
  SunriseBannerSkeleton,
  HeroSkeleton,
  MemberRewardsSkeleton,
  Section04Skeleton,
  HyperlinkBannerSkeleton,
  TestimonialsSkeleton,
  Section02Skeleton,
  Section01Skeleton,
} from '~/components/shared/Skeleton';
import { ElementInView } from '~/lib/ElementInView';
import Hero from '~/components/Layout/Hero';
import MonarchSection from '~/cohesion/Monarch/MonarchSection';
import { MonarchSlot, useMonarchRule, useMonarchSlot } from '@redventures/cohesion-utils-react';
import { lazy, useEffect } from 'react';
import { getAnonymousID } from '~/helper/parseURLParams';
import { setMonarchMonitoring } from '~/cohesion/Monarch/setMonarchMonitoring';

// Dynamic imports are not working with our skeletons with <MonarchSlot> due to Suspense overriding
// Once fix is implemented from RP team, dynamically import these components
const PromotionCTABanner = lazy(() => import('~/components/Layout/PromotionCTABanner'));
const AssistBanner = lazy(() => import('~/components/Layout/AssistBanner'));
const CompareCards = lazy(() => import('~/components/Layout/CompareCards'));
const Features = lazy(() => import('~/components/Layout/Features'));
const CTABanner = lazy(() => import('~/components/Layout/CTABanner'));
const SunriseBanner = lazy(() => import('~/components/Layout/SunriseBanner'));
const Testimonials = lazy(() => import('~/components/Layout/Testimonials'));
const FAQ = lazy(() => import('~/components/Layout/FAQ'));
const Layout = lazy(() => import('~/components/Layout'));
const MemberRewards = lazy(() => import('~/components/Layout/MemberRewards'));

export type MonarchEvaluationsValue = { [key: string]: any };

export default function Home() {
  type MonarchEvaluationsValue = { [key: string]: any };

  const evaluation = useMonarchRule<MonarchEvaluationsValue>('trafficFlow');
  const { slot } = useMonarchSlot<MonarchEvaluationsValue>('hero');

  // Send monitoring data to New Relic once on page load
  useEffect(() => {
    if (typeof window === 'undefined') return;

    setMonarchMonitoring();
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const evaluationReturnType = evaluation?.rule?.returnType;
    const hasAAHero = slot?.componentName.includes('AA');
    const heroComponentId = slot?.componentId ?? null;

    // Fire Beacon overlayView event for W+ tracking after Monarch evaluation
    if (
      typeof window._bcq !== 'undefined' &&
      typeof evaluationReturnType !== 'undefined'
    ) {
      const data = {
        nm: {
          nm: 'rvLandingPageExp',
        },
        ov: {
          nm: 'rvLandingPageExp',
        },
        co: {
          ty: 'RV MLP',
          mx: getAnonymousID(),
          nm: hasAAHero
            ? 'Control'
            : 'Exp',
          ms:
            heroComponentId
              ? `Hero header: ${heroComponentId}`
              : 'Hero header: 1'
        },
        pg: {
          nm: 'rvLandingPage',
        },
      };
      window._bcq.push([
        '_tagAction',
        'wplus',
        'overlayView',
        'rvLandingPageExp',
        data,
      ]);
    }
  }, [evaluation]);

  return (
    <>
      <Head>
        <title>Walmart+</title>
      </Head>
      <Layout>
        {/* Hero */}
        <MonarchSection
          elementPosition='HERO'
        >
          <MonarchSlot
            fallback={<HeroSkeleton />}
            slotId='hero'
          >
            <Hero />
          </MonarchSlot>
          {/* Section 01 */}
        </MonarchSection>
        <span id='trigger-hov-cta' />
        {/* We have an explanation of the ElementInView in our ReadMe */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_01'
              >
                <MonarchSlot
                  fallback={<Section01Skeleton />}
                  slotId='section01'
                >
                </MonarchSlot>
              </MonarchSection>
            ) : null
          }
        </ElementInView>
        {/* Section 02 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_02'
              >
                <MonarchSlot
                  fallback={<Section02Skeleton />}
                  slotId='section02'
                >
                </MonarchSlot>
              </MonarchSection>
            ) : null
          }
        </ElementInView>
        {/* Section 03 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_03'
              >
                <MonarchSlot
                  slotId='section03'
                >
                </MonarchSlot>
              </MonarchSection>
            ) : null
          }
        </ElementInView>
        {/* Section 04 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_04'
              >
                <MonarchSlot
                  slotId='section04'
                >
                  <PromotionCTABanner />
                </MonarchSlot>
              </MonarchSection>
            ) : (
              <Section04Skeleton />
            )
          }
        </ElementInView>
        {/* Section 05 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_05'
              >
                <MonarchSlot
                  fallback={<HyperlinkBannerSkeleton />}
                  slotId='section05'
                >
                  <AssistBanner />
                </MonarchSlot>
              </MonarchSection>
            ) : (
              <HyperlinkBannerSkeleton />
            )
          }
        </ElementInView>
        {/* Section 06 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_06'
              >
                <MonarchSlot
                  fallback={<TestimonialsSkeleton />}
                  slotId='section06'
                >
                  <Testimonials />
                </MonarchSlot>
              </MonarchSection>
            ) : (
              <TestimonialsSkeleton />
            )
          }
        </ElementInView>
        {/* Section 07 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_07'
              >
                <MonarchSlot
                  fallback={<FeaturesSkeleton />}
                  slotId='section07'
                >
                  <Features features={baseSiteFeatures} />
                </MonarchSlot>
              </MonarchSection>
            ) : (
              <FeaturesSkeleton />
            )
          }
        </ElementInView>
        {/* Section 08 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_08'
              >
                <MonarchSlot
                  fallback={<MemberRewardsSkeleton />}
                  slotId='section08'
                >
                  <MemberRewards />
                </MonarchSlot>
              </MonarchSection>
            ) : (
              <MemberRewardsSkeleton />
            )
          }
        </ElementInView>
        {/* Section 09 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_09'
              >
                <MonarchSlot
                  fallback={<BannerCTASkeleton />}
                  slotId='section09'
                >
                  <CTABanner />
                </MonarchSlot>
              </MonarchSection>
            ) : (
              <BannerCTASkeleton />
            )
          }
        </ElementInView>
        {/* Section 10 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_10'
              >
                <MonarchSlot
                  fallback={<CompareCardsSkeleton />}
                  slotId='section10'
                >
                  <CompareCards />
                </MonarchSlot>
              </MonarchSection>
            ) : (
              <CompareCardsSkeleton />
            )
          }
        </ElementInView>
        {/* Section 11 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_11'
              >
                <MonarchSlot
                  fallback={<SunriseBannerSkeleton />}
                  slotId='section11'
                >
                  <SunriseBanner />
                </MonarchSlot>
              </MonarchSection>
            ) : (
              <SunriseBannerSkeleton />
            )
          }
        </ElementInView>
        {/* Section 12 */}
        <ElementInView percentInView={-10}>
          {(isInView: boolean) =>
            isInView ? (
              <MonarchSection
                elementPosition='SECTION_12'
              >
                <MonarchSlot
                  fallback={<FAQSSkeleton />}
                  slotId='section12'
                >
                  <FAQ questions={baseSiteFAQs} />
                </MonarchSlot>
              </MonarchSection>
            ) : (
              <FAQSSkeleton />
            )
          }
        </ElementInView>
      </Layout>
    </>
  );
}
